.switchButtonContainer {
    width: 100%;
    border-radius: 0;
    padding: 0.5vh 0;
    border-bottom: 1px solid var(--bs-xploregroup-very-light);
    margin-bottom: 1vh;
}

[data-bs-theme='dark'] .switchButtonContainer {
    border-bottom: 1px solid var(--bs-xploregroup-very-dark-grayish-blue);
}
