.footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--bs-xploregroup-very-dark-grayish-blue);
    color: var(--bs-xploregroup-very-very-light);
    height: var(--bs-footer-heigth);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.footer p {
    margin: 0;
    font-size: small;
    text-align: center;
}
