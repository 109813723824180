.common-button {
    background-color: transparent;
    border-color: var(--bs-xploregroup-very-dark-grayish-blue);
}
.common-button:hover {
    background-color: var(--bs-xploregroup-very-dark-grayish-blue);
}

[data-bs-theme='dark'] .common-button {
    color: var(--bs-xploregroup-very-very-light);
}
