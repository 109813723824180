.form-switch .form-check-input {
    box-shadow: 2px 2px 2px var(--bs-xploregroup-dark-gray);
    border-width: 2px;
}

[data-bs-theme='dark'] .form-switch .form-check-input {
    box-shadow: 2px 2px 2px black;
}

.fullscreen-enabled {
    background: var(--bs-body-bg);
}

/*React select styling override*/

/* Light Mode styles (default) */
.react-select {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    border: 1px solid var(--bs-border-color);
}

.react-select__control {
    background-color: var(--bs-body-bg);
    border: 1px solid var(--bs-border-color);
    box-shadow: none; /* Reset shadow */
    border-radius: 0.25rem; /* Optional: Match Bootstrap styling */
}

.react-select__control--is-focused {
    border-color: var(--bs-primary);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-select__menu {
    background-color: var(--bs-body-bg);
    border: 1px solid var(--bs-border-color); /* Border for the dropdown */
    border-radius: 0.25rem; /* Match Bootstrap styling */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for dropdown */
}

.react-select__option {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
}

.react-select__option--is-selected {
    background-color: var(--bs-primary);
    color: var(--bs-body-bg);
}

.react-select__option--is-focused {
    background-color: #495057; /* Lighter grey for dark mode hover */
    color: var(--bs-body-color);
}

.react-select__single-value {
    color: var(--bs-body-color) !important;
}

.react-select__input {
    color: var(--bs-body-color) !important;
}

.react-select__placeholder {
    color: var(--bs-body-color);
}

.react-select__clear-indicator,
.react-select__dropdown-indicator {
    color: var(--bs-body-color);
}

.react-select__clear-indicator:hover,
.react-select__dropdown-indicator:hover {
    color: var(--bs-primary);
}

.react-select__indicator-separator {
    background-color: var(--bs-border-color);
}

/* Dark Mode styles */
[data-bs-theme='dark'] .react-select {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    border: 1px solid var(--bs-border-color);
}

[data-bs-theme='dark'] .react-select__control {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border: 1px solid var(--bs-border-color);
    box-shadow: none; /* Reset shadow */
    border-radius: 0.25rem; /* Optional: Match Bootstrap styling */
}

[data-bs-theme='dark'] .react-select__control--is-focused {
    border-color: var(--bs-primary);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

[data-bs-theme='dark'] .react-select__menu {
    background-color: var(--bs-body-bg);
    border: 1px solid var(--bs-border-color); /* Border for the dropdown */
    border-radius: 0.25rem; /* Match Bootstrap styling */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow for dropdown */
}

[data-bs-theme='dark'] .react-select__option {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
}

[data-bs-theme='dark'] .react-select__option--is-selected {
    background-color: var(--bs-primary);
    color: var(--bs-body-bg);
}

[data-bs-theme='dark'] .react-select__option--is-focused {
    background-color: #495057; /* Lighter grey for dark mode hover */
    color: var(--bs-body-color);
}

[data-bs-theme='dark'] .react-select__single-value,
[data-bs-theme='dark'] .react-select__input,
[data-bs-theme='dark'] .react-select__placeholder,
[data-bs-theme='dark'] .react-select__clear-indicator,
[data-bs-theme='dark'] .react-select__dropdown-indicator {
    color: var(--bs-body-color) !important;
}

[data-bs-theme='dark'] .react-select__clear-indicator:hover,
[data-bs-theme='dark'] .react-select__dropdown-indicator:hover {
    color: var(--bs-primary);
}

[data-bs-theme='dark'] .react-select__indicator-separator {
    background-color: var(--bs-border-color);
}
