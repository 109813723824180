.appNavbar {
    background-color: var(--bs-xploregroup-very-dark-grayish-blue);
    color: var(--bs-xploregroup-very-very-light);
    position: sticky !important;
    z-index: 1000;
    top: 0;
}

.appNavbar img {
    width: 30px;
    height: 30px;
}

.appNavbar div {
    background-color: var(--bs-xploregroup-very-dark-grayish-blue);
    color: var(--bs-xploregroup-very-very-light);
}

.appNavbar .dropdown-header {
    color: var(--bs-dropdown-header-color);
}

.appNavbar .brand {
    cursor: pointer;
    color: var(--bs-xploregroup-very-very-light);
    margin: 0 0.5vw 0 0;
}

.appNavbar a {
    color: var(--bs-xploregroup-very-very-light);
}

.appNavbar .hamburger {
    width: 15vw;
}

.dropdown-menu-dark {
    --bs-dropdown-link-active-bg: '' !important;
}

.appNavbar .my-pool-navlink,
.appNavbar .nav-hamburger-holder,
.appNavbar .management-dropdown,
.appNavbar .my-info-dropdown {
    position: relative;
}

.appNavbar .my-info-dropdown .my-info-badge,
.appNavbar .management-dropdown .management-badge,
.appNavbar .nav-hamburger-holder .nav-hamburger-badge,
.appNavbar .my-pool-badge,
.appNavbar .notification-badge {
    padding: 0.3em;
    background-color: var(--bs-xploregroup-red) !important;
}

.appNavbar .nav-hamburger-holder .nav-hamburger-badge {
    position: absolute;
    top: 0;
    translate: -400% -25%;
}
