.toggleContainer,
.toggleContainerAdmin {
    width: 40%;
    padding: 1%;
    display: grid;
    gap: 0;
    grid-auto-flow: row;
    justify-content: space-between;
    animation: slideDown 0.4s ease-out forwards;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        height: 0;
    }
    100% {
        transform: translateY(0);
        height: 100%;
    }
}

.toggleContainer {
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
        'title .'
        'poolNotification poolNotificationToggle'
        'available availableToggle'
        'finished finishedToggle';
}

.toggleContainerAdmin {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        'title .'
        'poolNotification poolNotificationToggle'
        'available availableToggle'
        'finished finishedToggle'
        'allUnknownFcToggleDesc allUnknownFcToggle'
        'incidentDescription incindentToggle'
        'blockedFcToggleDescription blockedFcToggle';
}

.toggleContainer > div,
.toggleContainerAdmin > div {
    margin-left: 2%;
    height: 2em;
}

.centerSelf {
    display: flex;
    align-items: center;
    text-align: left;
}

.title {
    grid-area: title;
}

.poolNotification {
    grid-area: poolNotification;
}

.available {
    grid-area: available;
}

.finished {
    grid-area: finished;
}

.poolNotificationToggle {
    grid-area: poolNotificationToggle;
}

.availableToggle {
    grid-area: availableToggle;
}

.finishedToggle {
    grid-area: finishedToggle;
}

.allUnknownFcToggleDesc {
    border-top: 1px solid var(--bs-xploregroup-very-dark-grayish-blue);
    grid-area: allUnknownFcToggleDesc;
    padding-top: 1em;
}

[data-bs-theme='dark'] .allUnknownFcToggleDesc {
    border-top: 1px solid var(--bs-xploregroup-dark-gray);
}

.blockedFcToggleDesc {
    grid-area: blockedFcToggleDescription;
    padding-top: 1em;
}

.allUnknownFcToggle {
    grid-area: allUnknownFcToggle;
}

.blockedFcToggle {
    grid-area: blockedFcToggle;
}

.incidentDescription {
    grid-area: incidentDescription;
    padding-top: 1em;
}

.incindentToggle {
    grid-area: incindentToggle;
}

.halfChecked .form-check-input:checked{
    background-color: orange;
    border-color: orange;

}

@media (max-width: 1200px) {
    .toggleContainer,
    .toggleContainerAdmin {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .toggleContainer,
    .toggleContainerAdmin {
        width: 62%;
    }
}

@media (max-width: 768px) {
    .toggleContainer,
    .toggleContainerAdmin {
        width: 100%;
        grid-template-columns: 1.6fr 0.4fr;
    }
}
