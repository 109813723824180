.inactiveUserSearchPage .inactiveUserSearchResultTable td p {
    margin-bottom: 0;
    padding: 0;
}

@media (max-width: 1200px) {
    /* Force table to not be like tables anymore */
    .inactiveUserSearchPage .inactiveUserSearchResultTable table,
    .inactiveUserSearchPage .inactiveUserSearchResultTable thead,
    .inactiveUserSearchPage .inactiveUserSearchResultTable tbody,
    .inactiveUserSearchPage .inactiveUserSearchResultTable th,
    .inactiveUserSearchPage .inactiveUserSearchResultTable td,
    .inactiveUserSearchPage .inactiveUserSearchResultTable tr {
        display: block;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable thead {
        display: none;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable th {
        font-weight: bold;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable tr {
        border: 1px solid var(--bs-xploregroup-very-very-light);
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td {
        /* Behave like a "row" */
        border: none;
        border-bottom: 1px solid var(--bs-xploregroup-very-light);
        position: relative;
        padding-left: 35%;
        text-align: left;
        min-height: 2.5em;
        height: fit-content;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td div.show {
        margin-bottom: 70%;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:before {
        /* Now like a table header */
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        text-align: left;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:last-child,
    .inactiveUserSearchPage .inactiveUserSearchResultTable th:last-child {
        text-align: left;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable tr:nth-child(odd) {
        background: var(--bs-xploregroup-light-gray);
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:nth-child(1):before {
        content: 'Firstname';
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:nth-child(2):before {
        content: 'Lastname';
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:nth-child(3):before {
        content: 'Email';
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:nth-child(4):before {
        content: 'Type';
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:nth-child(5):before {
        content: 'Last login';
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:nth-child(6):before {
        content: 'Last login';
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable td:nth-child(7):before {
        content: 'Action';
    }

    .pagination-button {
        width: 30%;
    }

    .inactiveUserSearchPage .inactiveUserSearchResultTable button {
        width: auto;
    }

    td {
        overflow: auto;
        white-space: nowrap;
    }
}
