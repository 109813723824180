.chargingHistoryPage {
    padding: 1em 2%;
}

.chargingHistoryPage .userStatDetail .userStatDetailValue {
    font-style: italic;
}

.user-stats-table {
    width: 100%;
    border-collapse: collapse;
}

.user-stats-table th,
.user-stats-table td {
    padding: 12px 8px;
    text-align: left;
}

.divider {
    height: 1px;
    background-color: var(--bs-gray-400); /* Adjust color as needed */
    margin: 16px 0; /* Space around the divider */
    width: 100%; /* Full width */
}

.no-results {
    text-align: center;
    padding: 20px;
    font-size: 1.5rem;
}
