.message {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
}

.dashboardForm {
    margin: 0;
    margin-left: 1%;
    padding: 0 5px 0 5px;
    overflow: hidden;
}

.transactionButton {
    margin: 1%;
}

.form-field-wrapper {
    margin-bottom: 1rem;
}

.validation-message {
    color: red;
    font-size: small;
}

[data-bs-theme='dark'] .validation-message {
    color: var(--bs-xploregroup-red);
}

form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-top: 1rem;
}

button {
    margin: auto;
    width: 40%;
}

.notification {
    margin-left: 1rem;
    padding-left: 2%;
}

.read-only {
    color: white;
    background-color: #cccccc;
}

.com-status {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: auto;
}

.modal-title {
    text-align: center;
    width: 100%;
}

.modal-body {
    text-align: center;
    width: 100%;
}

.location-title {
    display: flex;
    justify-content: space-between;
    margin: 0.5% auto auto;
    width: 90%;
}

.login-button {
    width: 20%;
    margin: auto auto 10px;
}

.initials-hover {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--bs-xploregroup-very-very-light);
    border: 1px solid var(--bs-xploregroup-very-light);
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    align-items: center;
}

[data-bs-theme='dark'] .initials-hover {
    background-color: var(--bs-body-bg);
}

.initials-name {
    display: flex;
    white-space: nowrap;
}

input::placeholder {
    opacity: 0.5 !important;
}

.readonly-field {
    background-color: #e9ecef; /* Bootstrap's disabled field background color */
    opacity: 0.65; /* Reduced opacity to mimic disabled state */
    pointer-events: none;
}

ol > li::marker {
    font-weight: bold;
}

/* Add more styles as needed */
@media (min-width: 750px) {
    .charging-stations th,
    .charging-stations td {
        font-size: 14px;
    }
}

@media (max-width: 1250px) {
    .location-title {
        margin-top: 1%;
    }
}

@media (max-width: 850px) {
    .location-title {
        margin-top: 2%;
    }
}

@media (max-width: 600px) {
    .location-title {
        margin-top: 3%;
    }

    .location-title h2 {
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 400px) {
    .location-title {
        margin-top: 5%;
    }
}
