.fuelcardForm-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: 1em auto;
}

.react-select__value-container {
    white-space: nowrap !important;
}

.react-select__multi-value {
    min-width: 40% !important;
}

.fuelcardForm-wrapper button {
    width: 100%;
    /* margin: auto; */
}

.fuelcardForm-wrapper .button-wrapper {
    display: flex;
    width: 90%;
    align-self: center;
}

.fuelcardForm-wrapper .form-field {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.fuelcardForm-wrapper button.scanFcButton {
    margin-bottom: 1em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    background-color: var(--bs-xploregroup-dark-blue);
    border-color: var(--bs-xploregroup-dark-blue);
}

.fuelcardForm-wrapper button.scanFcButton:hover {
    background-color: var(--bs-xploregroup-dark-blue-hover);
    border-color: var(--bs-xploregroup-dark-blue-hover);
}

.fuelcardForm-wrapper .scanFcFallbackButton {
    margin-bottom: 1em;
    border-radius: 0;
}

.fuelcardForm-wrapper .registrationButton {
    background-color: var(--bs-xploregroup-green);
}

.fuelcardForm-wrapper form {
    width: 95%;
}

.fuelcardForm-wrapper div {
    text-align: left;
}

.fuelcardForm-wrapper .fuelcardForm-input-pretext {
    width: 100%;
    border-radius: 6px 6px 0 0 !important;
    min-width: fit-content;
}

.fuelcardForm-wrapper .selectDropdown {
    width: 100%;
}

.fuelcardForm-wrapper .selectDropdown div {
    border-radius: 0 6px 6px 0;
}

.fuelcardForm-wrapper .fuelcardForm-input-postText {
    width: fit-content;
}

.fuelcardForm-wrapper .information-icon-button {
    width: fit-content;
    margin-bottom: 1em;
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.fuelcardForm-wrapper .fuelcardForm-input-pwEye {
    padding-bottom: 0.15em;
}

.fuelcardForm-wrapper h2 {
    align-self: center;
    text-align: center;
}

.fuelcardForm-wrapper .submitMessage {
    margin: 0 20% 0 20%;
}

.fuelcardFormFcIdWarning {
    font-weight: bold;
    color: darkorange;
    text-align: center;
    width: 100%;
    margin-top: 0;
}

.fuelcardForm-wrapper
    .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback):not(
        .serialNumber
    ) {
    border-radius: 0 6px 6px 0 !important;
}

.serialNumber {
    border-radius: 0 0 0 0 !important;
}

.fuelcardForm-wrapper input.form-check-input {
    box-shadow: 2px 2px 2px var(--bs-xploregroup-dark-gray);
    border-width: 2px;
}

[data-bs-theme='dark'] .fuelcardForm-wrapper input.form-check-input {
    box-shadow: 2px 2px 2px black;
}

.fuelcardForm-wrapper .disabled,
.fuelcardForm-wrapper .disabled:focus {
    background-color: var(--bs-secondary-bg);
    opacity: 1;
}

.fuelcardForm-wrapper .fuelcardForm-admin-user-details {
    border: 0.05em solid var(--bs-xploregroup-dark-blue);
    border-left: none;
    border-right: none;
    padding-top: 1em;
    margin-bottom: 1em;
}

[data-bs-theme='dark'] .fuelcardForm-wrapper .fuelcardForm-admin-user-details {
    border-color: var(--bs-xploregroup-dark-gray);
}

.fuelcardForm-wrapper .uar-user-preview,
.fuelcardForm-wrapper .uar-car-previs {
    font-style: italic;
}
.fuelcardForm-wrapper .uar-user-preview {
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .fuelcardForm-wrapper {
        width: 100%;
    }

    .fuelcardForm-wrapper .submitMessage {
        margin: 0 2% 0 2%;
    }

    .fuelcardForm-wrapper button {
        font-size: 90%;
    }

    .fuelcardForm-wrapper .button-wrapper {
        width: 95%;
    }

    .react-select__value-container {
        white-space: nowrap !important;
        overflow-x: scroll !important;
    }

    .fuelcardForm-wrapper .fuelcardForm-input-postText {
        border-radius: 0%;
    }

    .fuelcardForm-wrapper
        .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback):not(
            .serialNumber
        ) {
        margin-left: 0 !important;
        border-radius: 0 !important;
    }

    .fuelcardForm-wrapper
        .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: 0 !important;
    }

    .fuelcardForm-wrapper .selectDropdown div {
        border-radius: 0;
    }
}

@media (min-width: 576px) {
    .fuelcardForm-wrapper form {
        width: 80%;
    }

    .fuelcardForm-wrapper .fuelcardForm-input-pretext {
        width: 35%;
        border-radius: 6px 0 0 6px !important;
    }

    .fuelcardForm-wrapper .selectDropdown {
        width: 65%;
    }

    .fuelcardForm-wrapper button {
        font-size: 90%;
    }

    .fuelcardForm-wrapper .button-wrapper {
        width: 95%;
    }
}

@media (min-width: 768px) {
    .fuelcardForm-wrapper form {
        width: 70%;
    }

    .fuelcardForm-wrapper .fuelcardForm-input-pretext {
        width: 33%;
        border-radius: 6px 0 0 6px !important;
    }

    .fuelcardForm-wrapper .selectDropdown {
        width: 67%;
    }
}

@media (min-width: 992px) {
    .fuelcardForm-wrapper form {
        width: 60%;
    }
}

@media (min-width: 1200px) {
    .fuelcardForm-wrapper form {
        width: 50%;
    }
}
