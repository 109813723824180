body {
    margin: 0;
    font-family: 'Proxima Nova Regular', apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
    font-family: 'Proxima Nova Bold', apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

h3 {
    font-family: 'Proxima Nova Light', apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

:root {
    --bs-xploregroup-yellow: #ffd32f;
    --bs-xploregroup-yellow-rgb: 255, 211, 47;
    --bs-xploregroup-green: #009f7b;
    --bs-xploregroup-dark-blue: #363643;
    --bs-xploregroup-dark-blue-hover: #252532;
    --bs-xploregroup-violet: #826faf;
    --bs-xploregroup-red: #ff5d68;
    --bs-xploregroup-very-dark-grayish-blue: #40404b;
    --bs-xploregroup-dark-gray: #9b9b9b;
    --bs-xploregroup-light-gray: #d8d8d8;
    --bs-xploregroup-very-light: #e6e6e6;
    --bs-xploregroup-very-very-light: #f1f1f1;

    --bs-footer-heigth: 1.4em;
    --bs-content-min-heigth: calc(100vh - var(--bs-footer-heigth));
}

.small-gray-text {
    font-size: small;
    color: var(--bs-xploregroup-dark-gray);
}

.x-small-gray-text {
    font-size: x-small;
    color: var(--bs-xploregroup-dark-gray);
}
