.notificationMngmtPage {
    width: 100%;
    padding: 2vh 1vw 0 1vw;
}

.notificationMngmtPage .nav-tabs {
    margin-bottom: 1em;
}

.notificationMngmtPage .nav-tabs .nav-item button.nav-link {
    width: fit-content;
}

.notificationMngmtPage .notificationMngmtItemHolder {
    width: 35%;
    padding: 1vh 2vw;
}

.notificationMngmtPage .notificationMngmtItemHolder .notificationMngmtItem {
    display: grid;
    grid-template-columns: 0.75fr 0.25fr;
    grid-template-rows: 1fr;
    gap: 0.2em;
    grid-auto-flow: row;
    grid-template-areas: 'notificationMngmtItemTitle notificationMngmtItemButton';
}

.notificationMngmtPage .notificationMngmtItemHolder .notificationMngmtItem .notificationMngmtItemTitle {
    grid-area: notificationMngmtItemTitle;
}

.notificationMngmtPage .notificationMngmtItemHolder .notificationMngmtItem .notificationMngmtItemButton {
    grid-area: notificationMngmtItemButton;
}

@media (max-width: 1400px) {
    .notificationMngmtPage .notificationMngmtItemHolder {
        width: 40%;
    }
}

@media (max-width: 1200px) {
    .notificationMngmtPage .notificationMngmtItemHolder {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .notificationMngmtPage .notificationMngmtItemHolder {
        width: 60%;
    }
}

@media (max-width: 768px) {
    .notificationMngmtPage .notificationMngmtItemHolder {
        width: 80%;
    }
}

@media (max-width: 576px) {
    .notificationMngmtPage .notificationMngmtItemHolder {
        width: 100%;
    }
    .notificationMngmtPage .notificationMngmtItemHolder .notificationMngmtItem {
        grid-template-areas: 'notificationMngmtItemTitle' 'notificationMngmtItemButton';
    }
}
