.api-error-holder .error-detail {
    line-break: anywhere;
    margin-bottom: 0;
}

.api-error-holder button.btn-outline-primary:hover {
    background-color: var(--bs-xploregroup-violet);
    color: white;
}

.api-error-holder button.btn-outline-primary {
    border-color: var(--bs-xploregroup-violet);
    color: var(--bs-xploregroup-violet);
}
