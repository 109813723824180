.locationTitleContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0;
    grid-auto-flow: row;
    grid-template-areas: 'locationTitle buttons';
    margin-left: 2%;
    margin-right: 2%;
}


.locationTitleContainer .notificationSettingsButtonHolder:hover svg {
    animation-name: notificationButtonGear;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center;
}

.locationTitleContainer .avgOccupationIndicator,
.locationTitleContainer .refetchIndicator,
.locationTitleContainer .notificationSettingsButtonHolder {
    font-size: x-small;
    color: var(--bs-xploregroup-dark-gray);
    cursor: pointer;
    width: fit-content;
    padding: 0;
}

.locationTitleContainer .notificationSettingsButtonHolder svg {
    height: 1em;
    width: auto;
}

.locationTitleContainer .refetchIndicator svg {
    height: 1em;
    width: auto;
}

.locationTitleContainer .refetchIndicatorLoading svg {
    animation-name: refetchIndicatorLoadingKeyFrames;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
}

.locationTitle {
    justify-self: start;
    align-self: center;
    grid-area: locationTitle;
}

.buttons {
    justify-self: end;
    align-self: center;
    grid-area: buttons;
    display: flex;
    gap: 10px;
}

.locationButton {
    width: fit-content;
    min-width: 10vw;
    border-color: var(--bs-xploregroup-very-dark-grayish-blue);
    background-color: transparent;
}

button.locationButton:hover {
    background-color: var(--bs-xploregroup-very-dark-grayish-blue);
}

[data-bs-theme='dark'] .locationButton {
    color: var(--bs-xploregroup-very-very-light);
    border-color: var(--bs-xploregroup-very-very-light);
}

[data-bs-theme='dark'] button.locationButton:hover {
    color: var(--bs-xploregroup-very-very-light);
    border-color: var(--bs-xploregroup-very-very-light);
}

@keyframes refetchIndicatorLoadingKeyFrames {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes notificationButtonGear {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
