.loginBox {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.welcome {
    font-size: 50px;
}

.buttonContainer {
    width: 100%;
    box-sizing: border-box;
}

.loginButton,
.logoutButton {
    padding: 5% 10%;
    font-size: 1.5vw; /* Adjusted font size as a percentage of the viewport width */
    margin-bottom: 5%; /* Adjusted margin as a percentage of the container height */
    cursor: pointer;
    background-color: var(--bs-xploregroup-green);
    color: var(--bs-xploregroup-very-very-light);
    border: none;
    border-radius: 4px;
    width: 100%;
}

.logoutButton {
    background-color: var(--bs-xploregroup-very-dark-grayish-blue);
}

.signInMessage {
    font-size: 14px;
}

@media (max-width: 1000px) {
    .loginBox {
        width: 80%;
        height: 70%;
        flex-direction: column;
    }

    .welcome {
        font-size: 8.5vw; /* Adjusted font size for welcome message as a percentage of the viewport width */
    }

    .loginButton,
    .logoutButton {
        padding-top: 5%;
        padding-bottom: 5%;
        font-size: 5vw; /* Adjusted font size for buttons as a percentage of the viewport width */
    }

    .loginButtonWithRegister {
        font-size: 4.5vw;
    }
}

@media (max-height: 500px) {
    .loginBox {
        width: 60%;
        height: 50%;
        flex-direction: column;
    }

    .welcome {
        font-size: 5.5vw; /* Adjusted font size for welcome message as a percentage of the viewport width */
    }

    .loginButton,
    .logoutButton {
        padding-top: 5%;
        padding-bottom: 5%;
        font-size: 3.5vw; /* Adjusted font size for buttons as a percentage of the viewport width */
    }

    .loginButtonWithRegister {
        font-size: 3.5vw;
    }
}
