.logo-container {
    display: flex;
    justify-content: center;
    position: relative;
}

.logo-side {
    position: absolute;
    height: 100px;
    width: 115px;
    bottom: 0;
    opacity: 0.5;
    animation: circular-lightup 2s linear infinite;
}

.logo-side-1 {
    animation-delay: 0s;
}

.logo-side-2 {
    animation-delay: 0.5s;
}

.logo-side-3 {
    animation-delay: 1s;
}

.logo-side-4 {
    animation-delay: 1.5s;
}

@keyframes circular-lightup {
    0%,
    25% {
        opacity: 0.5;
    }
    30%,
    55% {
        opacity: 1;
    }
    60%,
    100% {
        opacity: 0.5;
    }
}
