.carInfoMngmtPage {
    width: 100%;
    padding: 2vh 2vw 0 2vw;
}

.carInfoMngmtPage .carInfoBrandMngmtItem .carInfoMngmtInputDecorationButton {
    width: fit-content;
}

.carInfoMngmtPage .carInfoBrandMngmtItem .carInfoBrandMngmtFormGroup {
    padding-bottom: 1vh;
}

.carInfoMngmtPage .carInfoBrandMngmtItem .carInfoModelMngmtFormGroup {
    padding-bottom: 1vh;
}

.carInfoMngmtPage .carInfoTabs .nav-item {
    padding-left: 1em;
}

.carInfoMngmtPage .carInfoTabs .nav-item button {
    width: fit-content;
}

.carInfoMngmtPage .carInfoTabs .nav-item .active {
    border-color: var(--bs-xploregroup-very-light);
    border-bottom-color: transparent;
}

[data-bs-theme='dark'] .carInfoMngmtPage .carInfoTabs .nav-item .active,
[data-bs-theme='dark'] .carInfoMngmtPage .tab-content {
    border-color: var(--bs-xploregroup-very-dark-grayish-blue) !important;
}

.carInfoMngmtPage .nav-tabs {
    border-color: var(--bs-xploregroup-very-dark-grayish-blue);
    border-bottom-color: transparent;
}

.carInfoMngmtPage .tab-content {
    border: solid 1px var(--bs-xploregroup-very-light);
    border-radius: 6px;
    padding: 0.5em 1em;
}
