.announcementMngmtPage {
    width: 100%;
    padding: 2vh 1vw 0 1vw;
}

.announcementMngmtItem {
    width: 100%;
    padding: 0 2vw;
}

.announcementMngmtItem form {
    margin: 1em 0;
    width: 100%;
}

.announcementMngmtItem form .announcementBtnContainer {
    width: 100%;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.announcementMngmtItem form button {
    min-width: fit-content;
    width: 25vw;
}
