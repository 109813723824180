.leaderBoardContainer .leaderBoardSelectLocation {
    margin-bottom: 1em;
}

.leaderBoardContainer .leaderboardTabs button,
.leaderBoardContainer .locationTabs button {
    width: fit-content;
}

.wallOfFamePodium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 28vh;
    width: 100%;
    padding: 0 2vw;
    margin-bottom: 0.5em;
    overflow: hidden;
}

.wallOfFamePodium .place {
    text-align: center;
    margin: 0 2vw;
    width: 20%;
    opacity: 0;
    animation: riseUp 1s ease-out forwards;
}

@keyframes riseUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.wallOfFamePodium .platform {
    width: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    padding: 1vh;
}

.wallOfFamePodium .platform p {
    margin: 0.5vh 0;
}

.wallOfFamePodium .trophy {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 5vh;
    height: 5vh;
}

.wallOfFamePodium .position {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

.wallOfFamePodium .name {
    font-size: 1.3rem;
}

.wallOfFamePodium .company {
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.8;
}

.wallOfFamePodium .first .platform {
    height: 30vh;
}

.wallOfFamePodium .second .platform {
    height: 25vh;
}

.wallOfFamePodium .third .platform {
    height: 20vh;
}

.wallOfFamePodium .gold {
    background: gold;
    background: linear-gradient(135deg, #ffd700, #ffcc33, #b8860b);
}

.wallOfFamePodium .silver {
    background: silver;
    background: linear-gradient(135deg, #c0c0c0, #d9d9d9, #a8a8a8);
}

.wallOfFamePodium .bronze {
    background: #cd7f32;
    background: linear-gradient(135deg, #cd7f32, #b87333, #8c4d21);
}

@media (max-width: 910px) {
    .wallOfFamePodium .trophy svg {
        height: 4.5vh;
        width: 4.5vh;
    }
}

@media (max-width: 768px) {
    .wallOfFamePodium {
        height: 25vh;
    }

    .wallOfFamePodium .name {
        font-size: 1.1rem;
    }

    .wallOfFamePodium .company {
        font-size: 0.8rem;
    }

    .wallOfFamePodium .first .platform {
        height: 25vh;
    }

    .wallOfFamePodium .second .platform {
        height: 20vh;
    }

    .wallOfFamePodium .third .platform {
        height: 17vh;
    }

    .wallOfFamePodium .place {
        width: 25%;
    }

    .wallOfFamePodium .trophy svg {
        height: 4vh;
        width: 4vh;
        padding-top: 5px;
    }

    .wallOfFamePodium .position {
        display: none;
    }
}

@media (max-width: 480px) {
    .wallOfFamePodium {
        height: 20vh;
    }

    .wallOfFamePodium .name {
        font-size: 0.9rem;
    }

    .wallOfFamePodium .company {
        font-size: 0.7rem;
    }

    .wallOfFamePodium .first .platform {
        height: 20vh;
    }

    .wallOfFamePodium .second .platform {
        height: 18vh;
    }

    .wallOfFamePodium .third .platform {
        height: 16vh;
    }

    .wallOfFamePodium .place {
        width: 30%;
    }

    .wallOfFamePodium .trophy svg {
        height: 3vh;
        width: 3vh;
        padding-top: 5px;
    }

}
