.pool {
    width: 90%;
    margin: 0.5% auto 2%;
    border-radius: 10px;
    display: grid;
    gap: 2vh;
    padding: 10px;
    box-sizing: border-box;
    grid-template-columns: repeat(5, 1fr); /* Default for larger screens */
}

.pool-item {
    position: relative;
    border: 1px solid var(--bs-xploregroup-dark-blue);
    border-radius: 10px;
    padding: 25px;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 5%;
    background: linear-gradient(white 0%, white 95%, var(--bs-xploregroup-very-very-light) 100%);
}

.pool-item--status-text {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 14px;
    padding: 1%;
    border: 1.5px solid;
    border-radius: 5px;
}

[data-bs-theme='dark'] .pool-item {
    border: 1px solid var(--bs-xploregroup-dark-blue);
    background: linear-gradient(
        var(--bs-xploregroup-dark-blue) 0%,
        var(--bs-xploregroup-dark-blue) 95%,
        var(--bs-xploregroup-very-dark-grayish-blue) 100%
    );
}

.pool-item-flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.pool-item-content p {
    margin-bottom: 0;
    margin-top: 10px;
}

.pool-item-content-subtext {
    color: var(--bs-xploregroup-dark-gray);
    font-size: 75%;
    font-style: italic;
}

.pool-add-button,
[data-bs-theme='dark'] .pool-add-button {
    cursor: pointer;
    color: white;
    border-color: var(--bs-xploregroup-green);
    background: linear-gradient(
        var(--bs-xploregroup-green) 0%,
        var(--bs-xploregroup-green) 95%,
        color-mix(in srgb, var(--bs-xploregroup-green) 80%, transparent) 100%
    );
    background-color: var(--bs-xploregroup-green); /* Fallback for when linear-gradient is not supported */


}

.pool-add-button p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.pool-add-button-plus-icon {
    font-size: 200%;
}

.pool-add-button:hover {
    filter: brightness(0.95);
}

.pool-item-buttons {
    margin-top: 0.5vh;
}

.pool-item-buttons button {
    width: fit-content;
    margin: 0 0.1vw;
}

.pool-item-buttons svg {
    height: 1rem;
    min-height: 10px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pool-item-buttons .btn-success {
    background-color: var(--bs-xploregroup-green);
}

.pool-item-buttons .btn-success:hover {
    filter: brightness(0.95);
}

.pool-item-buttons .btn-danger {
    background-color: var(--bs-xploregroup-red);
}

.pool-item-buttons .btn-danger:hover {
    filter: brightness(0.95);
}
.empty-pool {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 3 / 4;
    text-align: center;
}

.email-suggestions {
    max-height: 165px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    width: 100%;
}

/* For screens smaller than 1200px, show 3 items per row */
@media (max-width: 1200px) {
    .pool {
        grid-template-columns: repeat(3, 1fr);
    }
    .empty-pool {
        grid-column: 2/3;
    }
}

/* For screens smaller than 992px, show 3 items per row */
@media (max-width: 992px) {
    .pool {
        grid-template-columns: repeat(3, 1fr);
    }

    .pool-item--status-text {
        top: 7px;
        right: 7px;
    }

    .pool-item-content {
        margin-top: 15px;
    }

    .pool-item-content p {
        margin-top: 0;
    }
}

/* For screens smaller than 768px, show 2 items per row */
@media (max-width: 768px) {
    .pool {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5vh;
    }

    .empty-pool {
        grid-column: -1/1;
    }

    .pool-item-buttons button {
        width: fit-content;
        margin: 0 0.4vw;
    }

    .pool-item--status-text {
        top: 10px;
        right: 10px;
    }

    .pool-item-content {
        margin-top: 20px;
    }
}

/* For screens smaller than 480px, show 1 item per row */
@media (max-width: 480px) {
    .pool {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }

    .empty-pool {
        grid-column: 1/1;
    }

    .pool-item--status-text {
        top: 5px;
        right: 5px;
    }

    .pool-item-content {
        margin-top: 12px;
    }
}
