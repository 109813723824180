.switch-toggle {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
}

@media (max-width: 576px) {
    .switch-toggle {
        margin-left: 0;
    }

    .form-group {
        margin-bottom: 1rem;
    }

    .form-label {
        margin-bottom: 0;
    }
}
