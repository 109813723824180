button.common-dropdown-toggle {
    background-color: transparent;
    color: #212529;
    width: fit-content;
}

button.common-dropdown-toggle:hover {
    background-color: var(--bs-xploregroup-very-dark-grayish-blue);
    color: white;
}

[data-bs-theme='dark'] button.common-dropdown-toggle {
    color: var(--bs-xploregroup-very-very-light);
    border-color: var(--bs-xploregroup-very-very-light);
}
