.fuelcard-search-table-holder {
    overflow-y: auto;
    margin-bottom: 1vh;
}

.fuelcard-search-table {
    width: 90%;
    border-collapse: collapse;
    margin: 0.5vh auto 0;
}

.fuelcard-search-table .no-dropdown-arrow button:after {
    display: none;
}

.fuelcard-search-table th {
    position: sticky;
    top: 0;
    z-index: 200;
}

.fuelcard-search-table th,
.fuelcard-search-table td {
    padding: 6px;
    text-align: left;
    border: 1px solid var(--bs-xploregroup-light-gray);
}

[data-bs-theme='dark'] .fuelcard-search-table th,
[data-bs-theme='dark'] .fuelcard-search-table td {
    border: 1px solid var(--bs-xploregroup-very-dark-grayish-blue);
}

.fuelcard-search-table th {
    background-color: var(--bs-xploregroup-very-very-light);
}

.fuelcard-search-table tbody tr:nth-child(even) {
    background-color: var(--bs-xploregroup-very-very-light);
}

.fuelcard-search-table tbody tr:nth-child(odd) {
    background-color: var(--bs-xploregroup-very-light);
}

[data-bs-theme='dark'] .fuelcard-search-table th {
    background-color: var(--bs-xploregroup-dark-blue);
}

[data-bs-theme='dark'] .fuelcard-search-table tbody tr:nth-child(even) {
    background-color: var(--bs-xploregroup-dark-blue);
}

[data-bs-theme='dark'] .fuelcard-search-table tbody tr:nth-child(odd) {
    background-color: var(--bs-xploregroup-dark-blue-hover);
}

.fuelcard-search-table td:last-child,
.fuelcard-search-table th:last-child {
    text-align: center;
}

.fuelcard-search-table button {
    min-width: 4em;
}

.fuelcard-search-table td img {
    height: 1em;
    width: auto;
    filter: invert(94%) sepia(74%) saturate(3960%) hue-rotate(326deg) brightness(100%) contrast(103%);
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 0.5%;
    width: 90%;
}

.pagination-button {
    width: 15%;
}

@media (max-width: 1200px) {
    /* Force table to not be like tables anymore */
    .fuelcard-search-table table,
    .fuelcard-search-table thead,
    .fuelcard-search-table tbody,
    .fuelcard-search-table th,
    .fuelcard-search-table td,
    .fuelcard-search-table tr {
        display: block;
    }

    .fuelcard-search-table thead {
        display: none;
    }

    .fuelcard-search-table th {
        font-weight: bold;
    }

    .fuelcard-search-table tr {
        border: 1px solid var(--bs-xploregroup-very-very-light);
    }

    .fuelcard-search-table td {
        /* Behave like a "row" */
        border: none;
        border-bottom: 1px solid var(--bs-xploregroup-very-light);
        position: relative;
        padding-left: 35%;
        text-align: left;
        min-height: 2.5em;
        height: fit-content;
    }

    .fuelcard-search-table td div.show {
        margin-bottom: 70%;
    }

    .fuelcard-search-table td:before {
        /* Now like a table header */
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        text-align: left;
    }

    .fuelcard-search-table td:last-child,
    .fuelcard-search-table th:last-child {
        text-align: left;
    }

    .fuelcard-search-table tr:nth-child(odd) {
        background: var(--bs-xploregroup-light-gray);
    }

    .fuelcard-search-table .no-results td:nth-child(1):before {
        content: '';
    }

    .fuelcard-search-table td:nth-child(1):before {
        content: 'Display name';
    }

    .fuelcard-search-table td:nth-child(2):before {
        content: 'Name';
    }

    .fuelcard-search-table td:nth-child(3):before {
        content: 'Email';
    }

    .fuelcard-search-table td:nth-child(4):before {
        content: 'Mobile';
    }

    .fuelcard-search-table td:nth-child(5):before {
        content: 'License plate';
    }

    .fuelcard-search-table td:nth-child(6):before {
        content: 'Car';
    }

    .fuelcard-search-table td:nth-child(7):before {
        content: 'Last used';
    }

    .fuelcard-search-table td:nth-child(8):before {
        content: 'Last updated';
    }

    .fuelcard-search-table td:nth-child(9):before {
        content: 'Status';
    }

    .fuelcard-search-table td:nth-child(10):before {
        content: 'Actions';
    }

    .pagination-button {
        width: 30%;
    }

    .fuelcard-search-table button {
        width: auto;
    }

    td {
        overflow: auto;
        white-space: nowrap;
    }

}

@media (max-width: 768px) {
    tbody tr {
        cursor: default;
        pointer-events: auto !important;
    }
}