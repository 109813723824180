.feedback-off-canvas .feedback-error-detail-title,
.feedback-off-canvas .feedback-error-detail-value,
.feedback-off-canvas .feedback-disclaimer {
    font-size: smaller;
    color: var(--bs-xploregroup-dark-gray);
}

.feedback-off-canvas .feedback-textarea {
    margin-top: 1em;
    height: 25vh;
}

.feedback-off-canvas .feedback-error-detail-title {
    margin-top: 1em;
    margin-bottom: 0;
}

.feedback-off-canvas .feedback-error-detail-value {
    margin-bottom: 0;
    margin-left: 1em;
}

.feedback-off-canvas button {
    min-width: fit-content;
}
