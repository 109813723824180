.announcementContainer {
    margin: 1% auto 0 auto;
    width: fit-content;
    max-width: 95%;
    text-align: center;
}

.outOfServiceContainer .alert {
    margin: 1% auto;
    width: 95%;
}

.outOfServiceContainer .alert svg {
    height: 1em;
    width: auto;
}

.announcementContainer .alert,
.outOfServiceContainer .alert {
    background-color: rgba(var(--bs-xploregroup-yellow-rgb), 0.25);
    border-color: rgba(var(--bs-xploregroup-yellow-rgb), 0.5);
    color: var(--bs-xploregroup-very-dark-grayish-blue);
}

[data-bs-theme='dark'] .announcementContainer .alert,
[data-bs-theme='dark'] .outOfServiceContainer .alert {
    color: var(--bs-xploregroup-very-very-light);
}
